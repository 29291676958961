<template>
    <component :is="tagName" :class="classes" :disabled="loading || disabled">
        <slot v-if="!loading"></slot>
        <div v-if="loading" class="flex items-center justify-center">
            <svg
                v-if="loading"
                class="h-5 text-indigo-600 animate-spin mr-0.5"
                fill="none"
                height="24"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <line x1="12" x2="12" y1="2" y2="6"></line>
                <line x1="12" x2="12" y1="18" y2="22"></line>
                <line x1="4.93" x2="7.76" y1="4.93" y2="7.76"></line>
                <line x1="16.24" x2="19.07" y1="16.24" y2="19.07"></line>
                <line x1="2" x2="6" y1="12" y2="12"></line>
                <line x1="18" x2="22" y1="12" y2="12"></line>
                <line x1="4.93" x2="7.76" y1="19.07" y2="16.24"></line>
                <line x1="16.24" x2="19.07" y1="7.76" y2="4.93"></line>
            </svg>
            <slot></slot>
        </div>
    </component>
</template>

<script>
export default {
    name: "BaseButton",
    props: {
        variant: {
            type: String,
            default: "primary"
        },
        size: {
            type: String,
            default: "md"
        },
        tagName: {
            type: String,
            default: "button"
        },
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classes() {
            const variants = {
                none: "",
                primary: "rounded bg-indigo-700 text-white",
                secondary: "rounded border border-indigo-400 text-indigo-600",
                "circular-icon":
                    "text-xs rounded-lg px-1 py-1 border border-indigo-400 text-indigo-600",
                minor: "rounded border border-gray-400 text-gray-600",
                alert: "rounded border border-orange-500 text-orange-600",
                danger: "rounded border border-rose-500 text-rose-600",
                tab: "flex-1 text-xs uppercase font-medium rounded px-3 py-1.5 bg-transparent text-gray-500 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500",
                tabActive:
                    "flex-1 text-xs uppercase font-medium rounded px-3 py-1.5 bg-gray-100 text-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            };
            const sizes = {
                none: "",
                xs: "text-xs px-2 py-1.5",
                sm: "text-sm px-3 py-2",
                md: "text-sm px-3 py-2",
                lg: "text-base px-3 py-2"
            };
            return `${variants[this.variant]} ${sizes[this.size]} ${this.loading ? "opacity-50 cursor-not-allowed" : ""}`;
        }
    }
};
</script>

<style scoped>
button[disabled] {
    opacity: 0.5;
    cursor: default;
}
</style>
